import { Route, Routes, useLocation } from "react-router-dom";
import telegram from "./assets/telegram.jpg";

import {
  About,
  Dashboard,
  Exchange,
  ForgetPassword,
  Home,
  Login,
  Proof,
  Register,
  ResetPassword,
} from "./apps";
import { useEffect } from "react";
import { Privacy, Terms } from "./components";
import { useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import { client } from "./apps/UserDashboard/Deposit/Deposit";

function App() {
  const { pathname } = useLocation();
  const [testimony, setTestimony] = useState([]);
  const [counter, setCounter] = useState(1);
  const getProofs = () => {
    client
      .fetch(
        `*[_type == "proof"] {
            name,
           brief
        }`
      )
      .then((data) => {
        setTestimony(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      let prooflength = testimony.length - 1;
      if (counter === prooflength) {
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }
    }, 20000);
    toast.success(
      `${
        testimony.length === 0 ? "Profit Monitoring" : testimony[counter]?.name
      }: ${
        testimony.length === 0
          ? "Your Way To Riches"
          : testimony[counter]?.brief
      }`,
      {
        theme: "dark",
        transition: Slide,
      }
    );
  }, [counter]);

  useEffect(() => {
    getProofs();
  }, []);
  return (
    <>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Exchange />} path="/exchange" />
        <Route element={<About />} path="/about" />
        <Route element={<Proof />} path="/proof-of-reserve" />
        <Route element={<Login />} path="/auth/login" />
        <Route element={<Register />} path="/auth/register" />
        <Route element={<ResetPassword />} path="/auth/forget" />
        <Route element={<ForgetPassword />} path="/auth/reset/:id" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<Dashboard />} path="*" />
      </Routes>
      <ToastContainer />
      <a href="http://t.me/Ceoofprofitmonitoringorg" target="_blank">
        <img alt="telegram" src={telegram} className="tele_image" />
      </a>
    </>
  );
}

export default App;
