import React from "react";
import "./Account.css";
import { BiMoneyWithdraw } from "react-icons/bi";
import { RiLuggageDepositLine } from "react-icons/ri";
import { MdHistory } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { useEffect } from "react";
import { useGlobalContext } from "../../../context/context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Account = () => {
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const getSuccessfulDepositsAmount = JSON.parse(
    sessionStorage.getItem("deposits")
  );
  const withdraw = JSON.parse(sessionStorage.getItem("withdraw"));
  const {
    userDetails,
    getUser,
    userLoading,
    getAllDeposits,
    getPendingDepositsAmount,

    getAllWithdrawals,
  } = useGlobalContext();

  const { profit, bonus, country, symbol } = userDetails;
  const genSymbol = symbol === "" ? country?.symbol : symbol;

  useEffect(() => {
    getUser(accessToken);
    getAllDeposits(accessToken);
    getAllWithdrawals(accessToken);
  }, []);

  return (
    <section className="account_sect">
      <div className="account_header">
        <h1>
          {userLoading ? (
            <Skeleton
              baseColor="#fff"
              highlightColor="#444"
              width={200}
              height={50}
            />
          ) : (
            `${genSymbol}${
              getSuccessfulDepositsAmount + bonus + profit - withdraw
            }`
          )}
        </h1>
        {userLoading ? (
          <Skeleton
            height={15}
            width={250}
            baseColor="#fff"
            highlightColor="#444"
            style={{ margin: 20, marginBottom: 100 }}
          />
        ) : (
          <p>Your total Balance</p>
        )}
        <div className="account_list">
          <Link to={"/deposit"}>
            <RiLuggageDepositLine color="#071278" />
            <p>Deposit</p>
          </Link>
          <Link to={"/withdrawal"}>
            <BiMoneyWithdraw color="#071278" />
            <p>Withdraw</p>
          </Link>
          <Link to={"/settings"}>
            <FiSettings color="#071278" />
            <p>Settings</p>
          </Link>
          <Link to={"/transactions"}>
            <MdHistory color="#071278" />
            <p>Transaction</p>
          </Link>
        </div>
      </div>
      <div className="account_body">
        <div className="total_sect">
          {userLoading ? (
            <Skeleton
              height={15}
              width={250}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <p>Profits</p>
          )}
          {userLoading ? (
            <Skeleton
              width={250}
              height={30}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <div>
              <h3>
                {symbol}
                {profit}
              </h3>
            </div>
          )}
          {userLoading ? (
            <Skeleton
              height={15}
              width={250}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <p>Successful Deposit</p>
          )}

          {userLoading ? (
            <Skeleton
              width={250}
              height={30}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <div>
              <h3>
                {symbol}
                {getSuccessfulDepositsAmount}
              </h3>
            </div>
          )}
        </div>
        <div className="total_sect">
          {userLoading ? (
            <Skeleton
              height={15}
              width={250}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <p>Bonus</p>
          )}
          {userLoading ? (
            <Skeleton
              width={250}
              height={30}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <div>
              <h3>
                {symbol}
                {bonus}
              </h3>
            </div>
          )}
          {userLoading ? (
            <Skeleton
              height={15}
              width={250}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <p>Successful Withdrawal</p>
          )}
          {userLoading ? (
            <Skeleton
              width={250}
              height={30}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <div>
              <h3>
                {symbol}
                {0}
              </h3>
            </div>
          )}
        </div>
        <div className="total_sect">
          {userLoading ? (
            <Skeleton
              height={15}
              width={250}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <p>Pending Withdrawal</p>
          )}
          {userLoading ? (
            <Skeleton
              width={250}
              height={30}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <div>
              <h3>
                {symbol}
                {withdraw}
              </h3>
            </div>
          )}
          {userLoading ? (
            <Skeleton
              height={15}
              width={250}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <p>Pending Deposits</p>
          )}
          {userLoading ? (
            <Skeleton
              width={250}
              height={30}
              baseColor="#fff"
              highlightColor="#444"
              style={{ marginBottom: 20 }}
            />
          ) : (
            <div>
              <h3>
                {symbol}
                {getPendingDepositsAmount()}
              </h3>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Account;
