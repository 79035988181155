import React from "react";
import "./Modal.css";

import { motion } from "framer-motion";

// Icons
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ toggleVisible, message }) => {
  return (
    <div className="read_more_modal">
      <div className="modal_header">
        <h3>{""}</h3>
        <AiOutlineClose color="#fff" size={20} onClick={toggleVisible} />
      </div>

      <motion.div
        initial={{ opacity: 0, translateY: 100 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.35, delay: 0.2 }}
        className="modal_body"
      >
        <h3>{message?.message}</h3>
      </motion.div>
    </div>
  );
};

export default Modal;
