import React, { useEffect } from "react";
import "./Withdraw.css";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import { Button } from "../../../components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../../context/context";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPModal from "../Modal";

function Withdraw() {
  const getSuccessfulDepositsAmount = JSON.parse(
    sessionStorage.getItem("deposits")
  );
  const withdraw = JSON.parse(sessionStorage.getItem("withdraw"));

  const { handleSubmit, register } = useForm();
  const { userDetails, getUser, baseUrl } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const navigate = useNavigate();
  const { firstName, transferStep, profit, bonus, status, country, symbol } =
    userDetails;
  const genSymbol = symbol === "" ? country?.symbol : symbol;

  const [otp, setOtp] = useState(0);
  const total = getSuccessfulDepositsAmount + profit + bonus - withdraw;
  const [loading, setLoading] = useState(false);

  const handleSteps = (data) => {
    getUser(accessToken);
    data.userId = userDetails._id;
    let { amount, bankName, accountType, accountNumber, accountName } = data;

    if (status === "false") {
      toast.error("User cant make withdrawal now.");
      setTimeout(() => {
        navigate("/wallet");
      }, 3000);
    } else if (
      amount === "" ||
      bankName === "" ||
      accountName === "" ||
      accountNumber === "" ||
      accountType === ""
    ) {
      toast.error("All Inputs Field must be valid");
    } else if (amount < 500) {
      toast.error(`Withdrawal Amount must be greater than 500${genSymbol}`);
    } else if (amount > total) {
      toast.error("Insufficient Funds");
    } else if (genSymbol === "$") {
      toast.error(
        "Your withdrawal is in progress but you need to exchange your dollars to your local currency before the money can reflect in your bank account immediately, kindly contact support for more information",
        { transition: Slide, theme: "dark" }
      );
      setTimeout(() => {
        navigate("/wallet");
      }, 3000);
    } else if (
      transferStep !== 13 &&
      amount >= 500 &&
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      toast.success("Withdrawal in Progress");
      setTimeout(() => {
        setOtp(1);
      }, 2000);
    }
    if (
      transferStep === 13 &&
      amount >= 500 &&
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      setOtp(0);
      setLoading(true);
      axios
        .post(`${baseUrl}transfer`, data, {
          headers: { token: accessToken },
        })
        .then((data) => {
          toast.success("Withdrawal Successful");
          setLoading(false);
          setTimeout(() => {
            navigate("/transactions");
          }, 2000);
        })
        .catch((error) => {
          toast.error("Withdrawal Failed Contact Customer Care");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getUser(accessToken);
    if (transferStep === 2) {
      navigate("/account");
    } else if (transferStep === 4) {
      navigate("/account");
    } else if (transferStep === 6) {
      navigate("/account");
    } else if (transferStep === 8) {
      navigate("/account");
    } else if (transferStep === 10) {
      navigate("/account");
    } else if (transferStep === 12) {
      navigate("/account");
    }
  }, []);
  return (
    <section className="withdraw_sect">
      <DashboardHeader pathname={firstName} />
      <div className="withdraw_sect_hero">
        <h2>Enter Withdrawal Details</h2>
        <h3>Amount</h3>
        <input
          type="text"
          {...register("amount")}
          placeholder={`Put Amount In ${genSymbol}`}
        />
        <h3>Bank Name</h3>
        <input type="text" {...register("bankName")} />
        <h3>Account Type</h3>
        <input type="text" {...register("accountType")} />
        <h3>Name on Account</h3>
        <input type="text" {...register("accountName")} />
        <h3>Account Number</h3>
        <input type="text" {...register("accountNumber")} />
        <Button
          title={"Make Withdrawal"}
          icon={
            loading && (
              <ColorRing
                visible={true}
                height="30"
                width="30"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          background={"#071278"}
          color={"#fff"}
          action={handleSubmit((data) => handleSteps(data))}
        />
      </div>
      {otp === 1 ? <OTPModal /> : ""}
      <ToastContainer />
    </section>
  );
}

export default Withdraw;
